import React from "react";
import {Link} from "react-router-dom";
import styles from './footer.module.css'
import Logo from './../../assets/LogoFooter.svg'

export const Footer = () => {
    const currentYear = new Date().getFullYear();


    return (
        <div className={styles.footer}>
            <div className='container'>
                <div className={styles.footerContainer}>
                    <div className={styles.topCont}>
                        <img className={styles.logo} src={Logo} alt="Logo"/>
                        <div className={styles.nav}>
                            <ul className={styles.list}>
                                <Link className={styles.link} to="/">О нас</Link>
                                <Link className={styles.link} to="/ourservices">Наши услуги</Link>
                                <Link className={styles.link} to="/contacts">Контакты</Link>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <span className={styles.company}>© {currentYear} ООО «АйТи Проекты</span>
                </div>
            </div>
        </div>
    )
}

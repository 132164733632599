import React, { useEffect } from 'react';
import style from './googlemap.module.css';

function GoogleMap() {
    useEffect(() => {
        function initMap() {
            if (!window.google) {
                console.error('Google Maps API not loaded');
                return;
            }

            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 55.761482, lng: 37.605301 },
                zoom: 13,
                styles: [
                    { elementType: "geometry", stylers: [{ color: "##F6F6F6" }] },
                    { elementType: "labels.text.stroke", stylers: [{ color: "##F6F6F6" }] },
                    { elementType: "labels.text.fill", stylers: [{ color: "#000000" }] },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#ffffff" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#000000" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#ffffff" }],
                    },
                ],
            });

            const myLatLng = { lat: 55.761482, lng: 37.605301 };
            const marker = new window.google.maps.Marker({
                position: myLatLng,
                map: map,
            });

            const contentString = '<div style="font-size: 18px;">Москва, Большой Гнездниковский переулок 1с2</div>';

            const infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });

            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });
        }

        if (window.google) {
            initMap();
        } else {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap;
            document.head.appendChild(script);
        }
    }, []);

    return (
        <div className={style.map} id="map"></div>
    );
}

export default GoogleMap;

import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';

import styles from './aboutus.module.css'
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {Link} from "react-router-dom";
import BunnerImg from './../../assets/BunnerImg.png'
import IconAboutUs from './../../assets/IconAboutUs.png'
import IconAboutUs2 from './../../assets/IconAboutUs2.png'
import IconAboutUs3 from './../../assets/IconAboutUs3.png'
import AdvantagesImg from './../../assets/AdvantagesImg.svg'
import AdvantagesImg2 from './../../assets/AdvantagesImg2.svg'
import AdvantagesImg3 from './../../assets/AdvantagesImg3.svg'
import AdvantagesImg4 from './../../assets/AdvantagesImg4.svg'
import AdvantagesImg5 from './../../assets/AdvantagesImg5.svg'
import AdvantagesImg6 from './../../assets/AdvantagesImg6.svg'
import CompanyLogo from './../../assets/CompanyLogo.svg'
import CompanyLogo2 from './../../assets/CompanyLogo2.svg'
import CompanyLogo3 from './../../assets/CompanyLogo3.svg'
import CompanyLogo4 from './../../assets/CompanyLogo4.svg'
import CompanyLogo5 from './../../assets/CompanyLogo5.svg'
import CompanyLogo6 from './../../assets/CompanyLogo6.svg'
import CompanyLogo7 from './../../assets/CompanyLogo7.svg'
import CompanyLogo8 from './../../assets/CompanyLogo8.svg'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import {Pagination, Navigation} from 'swiper/modules';

export const AboutUs = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh'}}>
            <div>
                <Header active='aboutus'/>
                <div className='container'>
                    <div className={styles.bunner}>
                        <div className={styles.bunnerContainer}>
                            <h1 className={styles.title}>Эффективность и безопасность <span>IT-решений</span></h1>
                            <p className={styles.subtitle}>Обеспечьте безопасность своих данных и повысьте
                                производительность своей компании!</p>
                            <Link className={styles.contactus} to='/contacts'>Связаться с нами</Link>
                        </div>
                        <div className={styles.bunnerImg}>
                            <img src={BunnerImg} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.aboutus}>
                    <div className='container'>
                        <div className={styles.aboutusContainer}>
                            <div className={styles.aboutusInf}>
                                <span>О нас</span>
                                <h2>Аутсорсинг IT-услуг от экспертов отрасли</h2>
                                <p>Оказываем весь комплекс услуг IT аутсорсинга — от абонентского обслуживания
                                    компьютеров
                                    до
                                    программирования 1С и веб-разработки, при этом цены будут доступными, а тарифы —
                                    прозрачными! <br/><br/> Мы проводим анализ потребностей клиента и выбираем
                                    подходящие
                                    решения под цели
                                    обслуживаемого бизнеса.</p>
                            </div>
                            <div className={styles.aboutusExample}>
                                <div className={styles.guarantees}>
                                    <img src={IconAboutUs} alt=""/>
                                    <span>Гарантии</span>
                                    <p>Компания несет ответственность за свои работы</p>
                                </div>
                                <div className={styles.savings}>
                                    <img src={IconAboutUs2} alt=""/>
                                    <span>Экономия</span>
                                    <p>Компания обслуживает дешевле, чем нанимать в штат сотрудника</p>
                                </div>
                                <div className={styles.availability}>
                                    <img src={IconAboutUs3} alt=""/>
                                    <span>Доступность</span>
                                    <p>Аутсорсинговая компания не болеет и не уходит в отпуск</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.advantages}>
                    <div className='container'>
                        <h2 className={styles.advantagesTitle}>Преимущества IT-аутсорсинга</h2>
                        <div className={styles.advantagesCont}>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg} alt=""/>
                                <span>Полноценный IT отдел</span>
                                <p>Наша выделенная команда поддерживает всю IT-составляющую Вашего бизнеса</p>
                            </div>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg2} alt=""/>
                                <span>Эффективная техподдержка</span>
                                <p>Решаем 85% входящих обращений
                                    в течение часа удаленно</p>
                            </div>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg3} alt=""/>
                                <span>Защита от сбоев</span>
                                <p>Мы успешно предотвращаем около 80% сбоев, остальные оперативно устраняем в кратчайшие
                                    сроки</p>
                            </div>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg4} alt=""/>
                                <span>Экономия</span>
                                <p>Команда на аутсорсе обходится дешевле собственного штата специалистов</p>
                            </div>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg5} alt=""/>
                                <span>Опыт</span>
                                <p>Мы предлагаем воспользоваться нашими знаниями и опытом в области отраслевых решений и
                                    сетью партнерских отношений</p>
                            </div>
                            <div className={styles.advantagesExample}>
                                <img src={AdvantagesImg6} alt=""/>
                                <span>Персонализация</span>
                                <p>Для каждого клиента мы находим индивидуальный подход, тщательно изучая особенности
                                    его
                                    бизнеса</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.cases}>
                    <div style={{padding: 0}} className='container'>
                        <h2 className={styles.advantagesTitle}>Реализованные кейсы</h2>
                        <div className={styles.casesContainer}>
                            <Swiper
                                slidesPerView={"auto"}
                                centeredSlides={true}
                                spaceBetween={10}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className={styles.mySwiper}
                            >
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo} alt=""/></div>
                                    <span>CTRL лизинг</span>
                                    <p>
                                        Создание лизингового конвеера на базе Битрикс24. 1C-сопровождение. Обслуживание инфраструктуры.
                                    </p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo2} alt=""/></div>
                                    <span>GILK</span>
                                    <p>
                                        1C Сопровождение. Администрирование.
                                    </p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo3} alt=""/></div>
                                    <span>Сервис Плюс</span>
                                    <p>
                                        Создание корпоративного портала на Битрикс24. Администрирование.
                                    </p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo4} alt=""/></div>
                                    <span>Smartfact</span>
                                    <p>1С Разаработка. Сопровождение инфраструктуры. Хелп-деск.</p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo5} alt=""/></div>
                                    <span>Carbon Copy</span>
                                    <p>Реализация корпоративной CRM на Битрикс24. 1С разработка.</p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo6} alt=""/></div>
                                    <span>FNGroup</span>
                                    <p>Автоматизация рабочих процессов на 1С и Битрикс 24. Сопровождение инфраструктуры.</p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo7} alt=""/></div>
                                    <span>Балтийский Лизинг</span>
                                    <p>Реализация полного спектра сервисов для автоматизации бизнес-процессов.
                                        Администрирование.</p>
                                </SwiperSlide>
                                <SwiperSlide className={styles.slide}>
                                    <div><img src={CompanyLogo8} alt=""/></div>
                                    <span>SINE QUA NON</span>
                                    <p>Разработка корпоративных сайтов. Администрирование.</p>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{position: 'relative'}}>
                    <div className='container'>
                        <div className={styles.bunnerContactUs}>
                            <h2>Доверьте IT-аутсорсинг профессионалам!</h2>
                            <p>Сотрудничество с нами позволит вам сократить затраты на содержание IT-отдела и повысить
                                эффективность работы вашего бизнеса</p>
                            <Link to='/contacts'>Связаться с нами</Link>
                        </div>
                    </div>
                    <div className={styles.blackBackg}></div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

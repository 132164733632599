import './App.css';
import {AboutUs} from "./pages/AboutUs/AboutUs";
import {Contacts} from "./pages/Contacts/Contacts";
import {Route, Routes} from "react-router-dom";
import {OurServices} from "./pages/OurServices/OurServices";

function App() {
    function globalFunc() {
        console.log(this === window); // true
    }
    globalFunc();
  return (
    <>
        <Routes>
            <Route path="/" element={<AboutUs />} />
            <Route path="/ourservices" element={<OurServices />} />
            <Route path="/contacts" element={<Contacts />} />
        </Routes>
    </>
  );
}

export default App;

import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton';
import style from './burger.module.css'
import {Link, useNavigate} from 'react-router-dom';
import styles from "../Header/header.module.css";


export default function Burger({
                                   className,

                               }) {
    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    document.body.style.overflow = isHidden ? 'hidden' : '';


    return (
        <>
            <div className={`${style.hamburger} ${className}  ${isActive ? style.menu__open : ''}`}>
                <div className={style.hamburgerBox}>
                    <IconButton
                        style={{padding: 0, zIndex: 111}}>
                        <svg
                            className={`${style.ham} ${style.hamRotate} ${style.ham1} ${isActive ? style.active : ""}`}
                            viewBox="25 20 50 60"
                            width="80"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                className={`${style.line} ${style.top}`}
                                d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
                            />
                            <path className={style.line} d="m 30,50 h 40"/>
                            <path
                                className={`${style.line} ${style.bottom}`}
                                d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
                            />
                        </svg>

                    </IconButton>
                </div>
                <input style={{display: 'none'}} type="checkbox" id="menu-toggle"/>
                <label id="trigger" htmlFor="menu-toggle"></label>
                <label
                    onClick={() => {
                        setIsHidden(!isHidden);
                        setIsActive(!isActive);
                    }}
                    id="burger" htmlFor="menu-toggle">
                </label>

                {isHidden ? (<div
                    style={{animation: isHidden ? 'checked-anim-image 3s ease both' : 'not-checked-anim-image .6s both'}}
                    className={`${style.background}`}>
                </div>) : ''}
                <ul style={{
                    top: isHidden ? '-50px' : '',
                    animation: isHidden ? 'checked-anim 3s ease both' : 'not-checked-anim .6s both'
                }} id="menu">
                    <div style={{width: '100%'}} className='container'>
                        <div className={style.linkContainer}>
                            <Link className={style.linkBtn} to="/">О нас</Link>
                            <Link className={style.linkBtn} to="/ourservices">Наши услуги</Link>
                            <Link className={style.linkBtn} to="/contacts">Контакты</Link>
                            <a className={style.email} href="mailto:info@it-projects.pro">info@it-projects.pro</a>
                        </div>
                    </div>
                </ul>
            </div>
        </>
    );
};

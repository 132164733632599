import React, {useState, useRef, useEffect} from "react";
import styles from './ourservices.module.css'
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import ServicesIcon from './../../assets/ServicesIcon.svg'
import ServicesIcon2 from './../../assets/ServicesIcon2.svg'
import ServicesIcon3 from './../../assets/ServicesIcon3.svg'
import ServicesIcon4 from './../../assets/ServicesIcon4.svg'
import CicleTick from './../../assets/tick-circle.svg'
import CloseSvg from './../../assets/close.svg'

export const OurServices = () => {
    const [activeInformation, setActiveInformation] = useState(null);
    const modalWidthContRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 700);
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (activeInformation === null) {
            document.body.classList.remove('no-scrollbar');
        } else {
            document.body.classList.add('no-scrollbar');
        }

        return () => {
            document.body.classList.remove('no-scrollbar');
        };
    }, [activeInformation]);

    const modalContRef = useRef();

    const handleClickOutside = (event) => {
        if (modalContRef.current && !modalContRef.current.contains(event.target)) {
            setActiveInformation();
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh'}}
             ref={modalWidthContRef}>
            <div>
                <Header active='services'/>
                <div className='container'>
                    <div className={styles.services}>
                        <h2 className={styles.title}>Наши услуги</h2>
                        <p className={styles.subtitle}>Наша команда экспертов поможет создать эффективную систему, которая
                            будет отвечать всем вашим потребностям!</p>
                        <div className={styles.servicesCont}>
                            <div className={styles.servicesExample}>
                                <div className={styles.topCont}>
                                    <h4>Администрирование</h4>
                                    <img src={ServicesIcon} alt=""/>
                                </div>
                                <p className={styles.information}>Услуги по настройке рабочих компьютеров компании,
                                    установке и настройке программного обеспечения и прочее.</p>
                                <button onClick={() => setActiveInformation(1)} className={styles.button}>Подробнее</button>
                            </div>
                            <div className={styles.servicesExample}>
                                <div className={styles.topCont}>
                                    <h4>HELP DESK</h4>
                                    <img src={ServicesIcon2} alt=""/>
                                </div>
                                <p className={styles.information}>Услуги по автоматизации процессов техподдержки, сервиса,
                                    обслуживания сотрудников компании.</p>
                                <button onClick={() => setActiveInformation(2)} className={styles.button}>Подробнее</button>
                            </div>
                            <div className={styles.servicesExample}>
                                <div className={styles.topCont}>
                                    <h4>Поддержка / разработка 1с</h4>
                                    <img src={ServicesIcon3} alt=""/>
                                </div>
                                <p className={styles.information}>Установка и первоначальная настройка, регулярные
                                    обновления 1С. Услуги по доработке конфигурации.</p>
                                <button onClick={() => setActiveInformation(3)} className={styles.button}>Подробнее</button>
                            </div>
                            <div className={styles.servicesExample}>
                                <div className={styles.topCont}>
                                    <h4>веб-разработка</h4>
                                    <img src={ServicesIcon4} alt=""/>
                                </div>
                                <p className={styles.information}>Полный спектр работ: от проектирования и до запуска
                                    проекта и его последующей поддержки.</p>
                                <button onClick={() => setActiveInformation(4)} className={styles.button}>Подробнее</button>
                            </div>
                        </div>
                    </div>
                    {activeInformation === 3 && (
                        <div onClick={handleClickOutside} className={styles.modal}>
                            <div style={{
                                animation: activeInformation === 3 ? 'checked-anim-image 3s ease both' : 'not-checked-anim-image .6s both'
                            }} className={styles.blur}></div>
                            <div style={{
                                animation: activeInformation === 3 ? 'checked-anim-modal 3s ease both' : 'not-checked-anim-modal .6s both'
                            }} ref={modalContRef} onClick={(e) => e.stopPropagation()}
                                 className={`${styles.modalCont}`}>
                                <span className={styles.modalSubTitle}>наши услуги</span>
                                <h3 className={styles.modalTitle}>ПОДДЕРЖКА / РАЗРАБОТКА 1С</h3>
                                <div className={styles.example}>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка, внедрение программ 1С</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Сопровождение программ 1С</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Доработка, обновление программ 1С</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Поддержка серверов приложений и баз данных 1С</span>
                                    </div>
                                </div>
                                <button onClick={() => setActiveInformation(null)} className={styles.close}>
                                    <img src={CloseSvg} alt=""/>
                                </button>
                            </div>
                        </div>
                    )}
                    {activeInformation === 2 && (
                        <div onClick={handleClickOutside} className={styles.modal}>
                            <div style={{
                                animation: activeInformation === 2 ? 'checked-anim-image 3s ease both' : 'not-checked-anim-image .6s both'
                            }} className={styles.blur}></div>
                            <div style={{
                                animation: activeInformation === 2 ? 'checked-anim-modal 3s ease both' : 'not-checked-anim-modal .6s both'
                            }} ref={modalContRef} onClick={(e) => e.stopPropagation()}
                                 className={`${styles.modalCont}`}>
                                <span className={styles.modalSubTitle}>наши услуги</span>
                                <h3 className={styles.modalTitle}>help desk</h3>
                                <div className={styles.example}>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Техническая поддержка пользователей</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Удаленная настройка и обновление корпоративных сервисов (почта, сетевые ресурсы и др.)</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка принтеров, сканеров и МФУ</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Взаимодействие с поставщиками ИТ-услуг, консультации</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Управление запасами расходных материалов для оргтехники</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Поддержка и учет лицензий ПО</span>
                                    </div>
                                </div>
                                <button onClick={() => setActiveInformation(null)} className={styles.close}>
                                    <img src={CloseSvg} alt=""/>
                                </button>
                            </div>
                        </div>
                    )}
                    {activeInformation === 1 && (
                        <div onClick={handleClickOutside} className={styles.modal}>
                            <div style={{
                                animation: activeInformation === 1 ? 'checked-anim-image 3s ease both' : 'not-checked-anim-image .6s both'
                            }} className={styles.blur}></div>
                            <div style={{
                                animation: activeInformation === 1 ? 'checked-anim-modal 3s ease both' : 'not-checked-anim-modal .6s both'
                            }} ref={modalContRef} onClick={(e) => e.stopPropagation()}
                                 className={`${styles.modalCont}`}>
                                <span className={styles.modalSubTitle}>наши услуги</span>
                                <h3 className={styles.modalTitle}>Админимтрирование</h3>
                                <div className={styles.example}>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Установка, настройка и обслуживание офисного и прикладного ПО</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Обеспечение и контроль антивирусной защиты</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка принтеров, сканеров и МФУ</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Установка критических обновлений серверного программного обеспечения</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Мониторинг состояния серверной инфраструктуры: производительность, доступность</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Восстановление работоспособности в случае программных и аппаратных сбоев</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Анализ сети на наличие уязвимостей</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Обслуживание сложного сетевого оборудования</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Восстановление работоспособности устройств и сети</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Анализ и оптимизация ИТ-бюджетов</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка резервного копирования данных</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка и поддержка почтовых систем</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Установка почтового сервера. Настройка входящий и исходящих коннекторов</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка записей DNS: MX, SPF</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Проектирование, монтаж и настройка СКУД</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Подключение видеонаблюдения, настройка событий фиксации, организация хранения данных</span>
                                    </div>
                                </div>
                                <button onClick={() => setActiveInformation(null)} className={styles.close}>
                                    <img src={CloseSvg} alt=""/>
                                </button>
                            </div>
                        </div>
                    )}
                    {activeInformation === 4 && (
                        <div onClick={handleClickOutside} className={styles.modal}>
                            <div style={{
                                animation: activeInformation === 4 ? 'checked-anim-image 3s ease both' : 'not-checked-anim-image .6s both'
                            }} className={styles.blur}></div>
                            <div style={{
                                animation: activeInformation === 4 ? 'checked-anim-modal 3s ease both' : 'not-checked-anim-modal .6s both'
                            }} ref={modalContRef} onClick={(e) => e.stopPropagation()}
                                 className={`${styles.modalCont}`}>
                                <span className={styles.modalSubTitle}>наши услуги</span>
                                <h3 className={styles.modalTitle}>Веб-разработка</h3>
                                <div className={styles.example}>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Поддержка и обновления Битрикс 24</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Настройка приложений из маркетплейса Битрикс24</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Разработка модулей Битрикс 24 по ТЗ</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Разработка и внедрение бизнес-процессов в Битрикс 24</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Аудит и обучение работе в Битрикс24</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Интеграции Битрикс 24 с внешними системами</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Разработка веб-страниц и веб-сервисов для автоматизации бизнес процессов</span>
                                    </div>
                                    <div className={styles.decisions}>
                                        <img src={CicleTick} alt=""/>
                                        <span>Поддержка и сопровождение веб-страниц и веб-сервисов</span>
                                    </div>
                                </div>
                                <button onClick={() => setActiveInformation(null)} className={styles.close}>
                                    <img src={CloseSvg} alt=""/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

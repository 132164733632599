import React from "react";
import {Link} from "react-router-dom";
import styles from './header.module.css'
import Logo from './../../assets/IT Projects_logo.svg'
import Burger from "../Burger/Burger";

export const Header = ({active}) => {
    return (
        <div className={styles.header}>
            <div className='container'>
                <div className={styles.headerContainer}>
                    <Link className={styles.logo} to="/">
                        <img src={Logo} alt="Logo"/>
                    </Link>
                    <div className={styles.nav}>
                        <ul className={styles.list}>
                            <Link className={`${styles.link} ${active === 'aboutus' ? styles.active : ''}`} to="/">О
                                нас</Link>
                            <Link className={`${styles.link} ${active === 'services' ? styles.active : ''}`}
                                  to="/ourservices">Наши услуги</Link>
                            <Link className={`${styles.link} ${active === 'contacts' ? styles.active : ''}`}
                                  to="/contacts">Контакты</Link>
                        </ul>
                    </div>
                    <Burger/>
                </div>
            </div>
        </div>
    )
}

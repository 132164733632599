import React from "react";
import styles from './contacts.module.css'
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import style from "../../components/Burger/burger.module.css";

export const Contacts = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh'}}>
            <div>
                <Header active='contacts'/>
                <div className='container'>
                    <h2 className={styles.title}>Контакты</h2>
                    <div className={styles.contacts}>
                        <div className={styles.inform}>
                            <div className={styles.name}>
                                <h3>Москва</h3>
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.place}>
                                <span>Адрес</span>
                                <p>Большой Гнездниковский переулок, 1 стр. 2, БЦ “Вознесенский”, 2 этаж</p>
                            </div>
                            <div className={styles.email}>
                                <span>Email</span>
                                <a style={{marginBottom: '16px'}} className={style.email} href="mailto:info@it-projects.pro">info@it-projects.pro</a>
                                <span style={{fontSize: "16px"}} className={styles.textContact}>Оставьте заявку и мы свяжемся с Вами в течение дня</span>
                            </div>
                        </div>
                        <GoogleMap/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
